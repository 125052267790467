@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: rgb(241, 238, 231);
  font-family: "Poppins", sans-serif !important;
}

.header {
  border: 1px solid black;
  height: 4rem;
}

.main {
  width: 1280px;
  margin: auto;
}

.createTicket-main {
  margin: auto;
  // width: 1280px;
  background: none;
  padding: 20px;

  .form-box {
    display: flex;
    justify-content: space-between;
    // margin: 0 3% 3% 3%;

    .form-container {
      // width: 72%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      align-items: center;
      // padding: 1rem;
      background-color: white;
      border-radius: 12px;

      form {
        padding: 0 20px;
      }

      .upload-box {
        margin: auto;
        padding: 2rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border: thin dashed;
        align-items: center;
        text-align: center;
      }

      .create-button {
        // text-align: end;
      }

      .create-button button {
        // width: 10rem;
        padding: 0 50px;
        height: 50px;
        display: block;
        margin: 0 auto;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .right-box {
      // width: 25%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      background-color: white;
      border-radius: 12px;
    }
  }
}

.form-input {
  .ant-select-selector {
    height: 50px !important;
    display: flex;
    align-items: center;
  }
}

.TicketInformation-container {
  .ticket-info-row {
    > div {
      background: white;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      border-radius: 12px;
      padding: 20px !important;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid black;
    height: 40px;
  }
  button {
    width: 10rem;
  }
  .ticket-information {
    // border: 1px solid red;
    // padding: 1rem;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    // margin-bottom: 10px;
  }
  .upload-box {
    margin: auto;
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: thin dashed;
    align-items: center;
    text-align: center;
  }
  .Addcomment {
  }
}

.cardDiv {
  display: flex;
  justify-content: space-evenly;

  .card1 {
    width: 20%;
    background-color: rgb(147, 169, 189);
    p:first-child {
      line-height: 2px;
    }
  }

  .card1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0.6),
      rgba(230, 220, 220, 0)
    );
    filter: blur(1px);
    z-index: 1;
  }

  .card2 {
    width: 20%;
    background-color: rgb(125, 179, 93);
  }

  .card2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0.6),
      rgba(230, 220, 220, 0)
    );
    filter: blur(1px);
    z-index: 1;
  }

  .card3 {
    width: 20%;
    background-color: rgb(98, 141, 161);
  }

  .card3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0.6),
      rgba(230, 220, 220, 0)
    );
    filter: blur(1px);
    z-index: 1;
  }

  .card4 {
    width: 20%;
    background-color: rgb(247, 199, 145);
  }

  .card4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0.6),
      rgba(230, 220, 220, 0)
    );
    filter: blur(1px);
    z-index: 1;
  }
}

.tableDiv {
  align-items: center;
  padding: 5%;
  background-color: white;
  .tableHead {
    display: flex;
    justify-content: space-between;
    .searchDiv {
      display: flex;
      height: fit-content;
      padding-top: 10px;
      .search-icon {
        border-bottom: 1px solid black;
        display: block !important;
        Input {
          border: none !important;
          border-radius: 0px !important;
          box-shadow: none !important;
        }
      }
      .downloadDiv {
        padding: 7px;
      }
    }
  }
}

th {
  border: none !important;
}

.table-div-data table tr th {
  background-color: rgb(30, 84, 155) !important;
  color: white !important;
  border-bottom: 1px solid #aca8a8 !important;
  border-right: 1px solid #dddada !important;
  border-radius: 0px !important;
  position: relative !important;
  width: 10% !important;
  size: 5rem;
}

th.ant-table-cell::before {
  width: 0px !important;
}

tr:nth-child(2),
tr:nth-child(3),
tr:nth-child(4),
tr:nth-child(5),
tr:nth-child(6) {
  background-color: rgb(199, 190, 171) !important;
}

p {
  padding-top: 0%;
}

span.ant-input-group-addon:first-child {
  border: none !important;
  background: white !important;
}

span.ant-input-group-addon:last-child {
  border: none !important;
  background: white !important;
}

.wifi-icon {
  padding-bottom: 0px;
}

.firstLine {
  margin-top: 0px;
}

.inputStyle {
  border-top: none !important;
}

.form-container {
  position: relative;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  // height: 100vh;
  // width: 100%;
  transform: translate(-50%, -50%);
}
.blur {
  filter: blur(5px);
  pointer-events: none;
}
tr.ant-table-row {
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 650px) {
  .main {
    width: 100%;
  }
  .form-container {
    width: 100%;
  }
  .cardDiv {
    width: max-content;
  }

  .tableOutDiv {
    width: auto;
  }

  .tableDiv {
    align-items: center;
    width: fit-content;
  }
}
